@import '../../../variables.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/7.4.1/swiper-bundle.min.css';

.sliderContainer, .slide {
    position: relative;
    width: 100%;
    height: 460px;
    background-size: cover;
    background-position: 80%;
    background-repeat: no-repeat;
}

.slideOverlay {
    background: linear-gradient(90deg, #111849 0%, rgba(0, 0, 0, 0) 157.14%);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.content {
    max-width: 750px;
    padding: 65px 0 116px 100px;
    z-index: 1;
}

.whiteBtn {
    padding: 10px 30px;
    border: 2px solid transparent;
    background: var(--ark-primary-white-figma);
    color: var(--ark-primary-black-figma);
}

.whiteBtn:hover {
    border: 2px solid var(--ark-primary-white-figma);
    color: var(--ark-primary-white-figma);
    background: none;
}

.pagination {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 8px;
}

.pagination .pageButton {
    position: relative;
    background: none;
    width: 45px;
    height: 4px;
    border: 1px solid var(--ark-primary-white-figma);
    border-radius: 3px;
}

.pagination .pageButton:before {
    content: '';
    position: absolute;
    /* half of :before height */
    top: -20px;
    left: 0;
    /* same width as pageButton */
    width: 45px;
    height: 40px;
    display: block;
    cursor: pointer;
}

@media (hover: hover) {
    .pagination .pageButton:hover {
        background: var(--ark-neutral-black-50-figma);
        border-color: var(--ark-neutral-black-50-figma);
    }
}

.pagination .pageButton.active {
    background: var(--ark-primary-white-figma);
}

.pagination .handlerBtn {
    height: 40px;
    border: none;
    background: none;
    padding: 0;
    margin-right: 8px;
    color: var(--ark-primary-white-figma);
}

.pagination .handlerBtn:hover {
    color: var(--ark-neutral-black-50-figma);
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .content {
        padding: 78px 0 72px 64px;
    }

    .slide h1 {
        font-size: var(--ark-44-font-size);
        margin-bottom: 0.5rem;
        max-width: 380px;
    }

    .slide h2 {
        font-size: var(--ark-18-font-size);
        margin-bottom: 2rem;
        max-width: 380px;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0;
    }

    .content.mobile {
        justify-content: end;
        padding-bottom: 100px;
    }

    .slide h1,
    .slide h2 {
        text-align: center;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .sliderContainer, .slide {
        background-position: 50%;
    }

    .slide h1 {
        font-size: var(--ark-32-font-size);
    }

    .slide h2 {
        font-size: var(--ark-16-font-size);
        padding: 0 var(--ark-spacing-s);
    }
}
