@import '../../../../variables.css';

.content {
    width: 100%;
    min-height: 800px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding-top: 26px;
    margin: 0 auto;
    font-family: 'Readex Pro', sans-serif;
    font-weight: var(--ark-font-weight-regular);
}

.title {
    max-width: 877px;
    margin: 0 auto;
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-72-line-height);
    font-weight: var(--ark-font-weight-medium);
    text-align: center;
    color: #0E1858;
}

.cardsContainer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
}

.card {
    width: 292px;
    height: 350px;
    border: 1px solid #9912D0;
    border-radius: 16px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--ark-neutral-white);
}

.cardTitle {
    text-align: center;
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: #0E1858;
}

.cardSubtitle {
    text-align: center;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: #0E1858;
}

.cardImage {
    width: 100%;
    height: auto;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .content {
        padding: 100px 10px 80px;
    }

    .cardsContainer {
        column-gap: 16px;
    }

    .title {
        font-size: var(--ark-40-font-size);
        line-height: var(--ark-64-line-height);
    }
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .content {
        padding: 30px 15px 0;
        min-height: 670px;
    }

    .title {
        max-width: unset;
        margin: 0;
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .cardsContainer {
        margin-top: 50px;
        flex-direction: column;
        column-gap: unset;
        row-gap: 16px;
    }

    .card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 112px;
        padding: 0;
        background-color: var(--ark-neutral-white);
    }

    .card:nth-child(1) {
       padding-right: 29px;
    }

    .card:nth-child(2) {
        padding-left: 28px;
    }

    .card:nth-child(2) .cardContent {
        order: 2;
    }

    .card:nth-child(2) .cardImage {
        order: 1;
    }

    .card:nth-child(3) {
        padding-right: 21px;
    }

    .cardContent {
        width: 181px;
    }

    .cardTitle {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-20-line-height);
    }

    .cardSubtitle {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }

    .cardImage {
        width: auto;
        height: 100%;
        border-radius: 16px;
    }
}