@import '../../../../variables.css';

.content {
    width: 100%;
    min-height: 892px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding-top: 136px;
    margin: 0 auto;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 400;
}

.title {
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-72-line-height);
    font-weight: var(--ark-font-weight-medium);
    text-align: center;
    color: #FFFFFF;
}

.subtitle {
    margin-top: 8px;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    color: #FFFFFF;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .content {
        width: 100%;
        min-height: 892px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        padding-top: 136px;
        margin: 0 auto;
        font-family: 'Readex Pro', sans-serif;
        font-weight: 400;
    }

    .title {
        max-width: 682px;
        margin: 0 auto;
        font-size: var(--ark-40-font-size);
        line-height: var(--ark-56-line-height);
    }

    .subtitle {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-32-line-height);
    }
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .content {
        padding: 96px 15px 0;
        min-height: 685px;
    }

    .title {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .subtitle {
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-18-line-height);
    }
}
