@import '../../../../variables.css';

.content {
    width: 100%;
    padding: 150px 0 70px 210px;
    display: flex;
    column-gap: 60px;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 541px;
    font-family: 'Readex Pro', sans-serif;
    font-weight: var(--ark-font-weight-regular);
}

.content .rightSide .title {
    text-align: left;
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-72-line-height);
    font-weight: var(--ark-font-weight-medium);
}

.content .rightSide .text {
    text-align: left;
    margin-top: 3px;
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    max-width: 600px;
}

.content .rightSide .text ul {
    list-style: disc;
    padding-left: 30px;
}

.content .rightSide .text b {
    font-weight: var(--ark-font-weight-regular-bolder);
}

.content .button {
    position: relative;
    margin-top: 33px;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #12B76A;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-medium);
    color: var(--ark-neutral-white);
    border-radius: 24px;
    border: unset;
}

.content .button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    bottom: -3px;
    background-color: #027A48;
    z-index: -1;
}

.content .button:hover {
    background-color: #027a48;
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .content {
        padding: 200px 0 220px;
        justify-content: center;
    }

    .content .rightSide .title {
        font-size: var(--ark-40-font-size);
        line-height: var(--ark-56-line-height);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .content {
        padding: 115px 0 80px;
        background-position: top left -1px;
    }

    .content .rightSide .text {
        margin-top: 11px;
        max-width: 447px;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .content {
        padding: 60px 20px 110px;
        flex-direction: column;
        column-gap: unset;
        row-gap: 24px;
        background-position: top center;
    }

    .leftSide {
        width: max-content;
    }

    .leftSideImage {
        width: 200px;
        height: auto;
    }

    .content .rightSide .title {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .content .rightSide .text {
        margin-top: 24px;
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-24-line-height);
        max-width: unset;
    }

    .content .rightSide .button {
        margin-top: 24px;
    }
}
