@import '../../../../variables.css';

.content {
    width: 100%;
    min-height: 546px;
    background: linear-gradient(180deg, #101A5C 0%, #2E3CA3 100%);
    padding-top: 80px;
    margin: 0 auto;
    font-family: 'Readex Pro', sans-serif;
    font-weight: var(--ark-font-weight-regular);
}

.title {
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-72-line-height);
    font-weight: var(--ark-font-weight-medium);
    text-align: center;
    color: var(--ark-neutral-white);
}

.subtitle {
    margin-top: 16px;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    color: #FFFFFF;
}

.authorAvatar {
    margin: 0 auto;
    width: 103px;
    height: auto;
}

.quoteText {
    margin-top: 32px;
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    color: var(--ark-neutral-white);
}

.quoteAuthor {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    color: var(--ark-neutral-white);
}

.slideContent {
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 95px;
    z-index: 1;
}

.content .pagination {
    margin-top: 44px;
    position: static;
    transform: unset;
    justify-content: center;
}

.content .quoteSlide {
    height: unset;
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .title {
        font-size: var(--ark-40-font-size);
        line-height: var(--ark-56-line-height);
    }

    .content .quoteSlide {
        height: unset;
    }

    .content .pagination {
        margin-top: 58px;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .content {
        padding: 84px 15px 0;
        min-height: 746px;
    }

    .title {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .subtitle {
        margin-top: 8px;
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-18-line-height);
    }

    .content .quoteSlide {
        height: unset;
    }

    .slideContent {
        padding-top: 59px;
    }

    .content .pagination {
        margin-top: 107px;
    }

    .quoteText {
        line-height: var(--ark-40-line-height);
    }

    .quoteAuthor {
        margin-top: 16px;
    }
}
