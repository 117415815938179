@import '../../../../variables.css';

.content {
    width: 100%;
    min-height: 892px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 16px 0 12px;
    margin: 0 auto;
    font-family: 'Readex Pro', sans-serif;
    font-weight: var(--ark-font-weight-regular);
}

.title {
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-72-line-height);
    font-weight: var(--ark-font-weight-medium);
    text-align: center;
    color: #F9F9F9;
}

.cardsContainer {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    column-gap: 34px;
}

.pricingTariffSlide {
    display: none;
}

.card {
    position: relative;
    border: 1px solid #D1D0D0;
    border-radius: 16px;
    background-color: #FFFFFF;
    z-index: 1;
}

.cardHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    text-transform: uppercase;
    background-color: #9912D0;
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-40-line-height);
    font-weight: var(--ark-font-weight-regular-bolder);
    color: var(--ark-neutral-white);
    text-align: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.cardHeader span {
    display: inline-block;
    margin-right: 5px;
}

.cardContainer {
    padding: 34px 70px 32px 60px;
    min-height: 610px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricePlan {
    font-size: var(--ark-32-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-regular-bolder);
    text-align: left;
    color: #000000;
    text-transform: capitalize;
}

.priceTitle {
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-regular-bolder);
    text-align: left;
    color: #0B0914;
}

.pricePeriod {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: left;
    color: #0B0914;
}

.priceSubtitle {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular-bolder);
    text-align: left;
    color: #4E4B4C;
}

.benefitsList {
    margin-top: 32px;
    list-style: none;
}

.benefitsItem {
    position: relative;
    margin-top: 24px;
    padding-left: 32px;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: left;
    color: #000000;
}

.benefitsItem b {
    font-weight: var(--ark-font-weight-regular-bolder);
}

.benefitsItem:nth-child(1) {
    margin-top: 0;
}

.benefitsItem::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("icons/icon-checkmark-dark.svg");
}

.card.promotion .benefitsItem::before {
    background-image: url("icons/icon-checkmark.svg");
}

.cardButton {
    padding: 0;
    border: 2px solid #000000;
    border-radius: 24px;
    width: 320px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-medium);
    color: #000000;
    background: none;
}

.cardButton:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.card.promotion::after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: -2px;
    right: -2px;
    background:
            linear-gradient(#FFFFFF, #FFFFFF) padding-box,
            linear-gradient(96.1deg, #694B14 3.79%, #FFD04D 25.82%, #614717 66.43%, #FFE6B8 94.65%) border-box;
    border: 2px solid transparent;
    border-radius: 20px;
    z-index: -11;
}

.card.promotion .cardButton {
    position: relative;
    background-color: #12B76A;
    color: #FFFFFF;
    border: unset;
}

.card.promotion .cardButton::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    bottom: -3px;
    background-color: #027A48;
    z-index: -1;
}

.card.promotion .cardButton:hover {
    background-color: #027a48;
}

.note {
    max-width: 1200px;
    margin: 64px auto 0;
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-regular);
    text-align: center;
    color: #F9F9F9;
}

.subscriptionLinks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--ark-neutral-white);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-24-line-height);
    padding-top: 56px;
}

.subscriptionLinks a {
    color: var(--ark-neutral-white);
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .title {
        font-size: var(--ark-40-font-size);
        line-height: var(--ark-56-line-height);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .content {
        padding: 0 15px 57px;
        min-height: unset;
    }

    .title {
        margin-bottom: 55px;
    }

    .cardsContainer {
        display: none;
    }

    .pricingTariffSlide {
        display: block;
        min-height: 731px;
    }

    .pricingTariffPagination {
        bottom: 0;
    }

    .note {
        max-width: 692px;
        margin: 48px auto 0;
    }

    .card {
        max-width: 362px;
        margin: 0 auto;
    }

    .cardHeader {
        font-size: var(--ark-24-font-size);
    }

    .cardContainer {
        padding: 32px;
    }

    .pricePlan {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .priceTitle {
        font-size: var(--ark-32-font-size);
        line-height: var(--ark-48-line-height);
    }

    .pricePeriod, .priceSubtitle, .benefitsItem {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }

    .cardButton {
        margin: 0 auto;
        width: 220px;
        height: 48px;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-767)) {
    .content {
        padding: 0 15px;
        min-height: 963px;
    }

    .title {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
        margin-bottom: 44px;
    }

    .cardsContainer {
        margin-top: 44px;
    }

    .pricingTariffSlide {
        min-height: 726px;
    }

    .pricingTariffPagination {
        bottom: 0;
    }

    .note {
        max-width: unset;
        margin: 33px auto 0;
        text-align: left;
    }

    .cardHeader {
        font-size: var(--ark-24-font-size);
    }

    .cardContainer {
        padding: 32px;
    }

    .pricePlan {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-40-line-height);
    }

    .priceTitle {
        font-size: var(--ark-32-font-size);
        line-height: var(--ark-48-line-height);
    }

    .pricePeriod, .priceSubtitle, .benefitsItem {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }

    .cardButton {
        margin: 0 auto;
        width: 262px;
        height: 48px;
    }
}
