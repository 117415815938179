@import '../../../../variables.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    text-align: center;
    color: var(--ark-primary-white-figma);
    font-weight: var(--ark-font-weight-bold);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .container {
        gap: 0;
        /*padding: 0 16px;*/
        background-position: top;
        background-size: contain;
    }
}